import Mape from './core'

import('controllers/' + Mape.RAILS.controller + '/' + Mape.RAILS.action).then( (module) => {
	$(document).ready( () => module.Controller(Mape) );

}).catch( (error) => {
	if ( Mape.RAILS.env === 'development' || Mape.RAILS.env === 'test' ) {
		console.warn("[DEV] No module for '/controllers/" + Mape.RAILS.controller + "/" + Mape.RAILS.action + ".js'");
	}
});
