import 'bootstrap'

// Yarn packages
import 'datatables'
import 'parsleyjs'
import 'select2'
import 'zebra_datepicker'

// Vendor libs
import "flot/source/jquery.canvaswrapper"
import "flot/source/jquery.colorhelpers"
import "flot/source/jquery.flot"
import "flot/source/jquery.flot.uiConstants"
const flotReq = require.context("../../../node_modules/flot/source/", true, /flot.*\.js$/)
flotReq.keys().forEach(flotReq);
require('./mprogress')
