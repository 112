import Mape from 'mape/core'

var ItemDialog = function(opts = {}) {
	this.settings = Object.assign({
		modalOpts: { show: false }
	}, opts);

	this.requestParams = this.settings.params || {};
	this.listener      = $(this);
	this.progressBar   = new Mprogress({ template: 3 });
};

/**
 * Hides the modal.
 */
ItemDialog.prototype.hide = function () {
	this.container.modal('hide');
};

/**
 * Shows the modal.
 */
ItemDialog.prototype.show = function () {
	this.container.modal('show');
};

/**
 * Toggles the modal's visibility.
 */
ItemDialog.prototype.toggle = function () {
	this.container.modal('toggle');
};

/**
 * Updates the modal's content based on item id.
 * @fires  dialog:added
 * @fires  dialog:ready
 * @param  {Integer|String} item_id - The target item's ID.
 */
ItemDialog.prototype.update = function (item_id) {
	// avoid loading the current item again
	if (item_id == this.currentItemId) {
		this.show();

	} else {
		this.progressBar.start();

		// set flag to only load modal content if layout has already been loaded
		if (this.hasLayout && !this.requestParams.contentOnly)
			this.requestParams = Object.assign( this.requestParams, { contentOnly: true } );

		// get new content and update container html
		const request = $.post(Mape.PATHS.digestedItem.replace(/:id/, item_id), this.requestParams);

		request.done((html) => {
			this.currentItemId = item_id;

			// only content load:
			// update the modal's content
			if ( this.hasLayout ) {
				this.container.find('.modal-content').html( html );

			// first time load:
			// load and set the modal object w/ content
			} else {
				this.container = $(html).modal( this.settings.modalOpts );
				this.hasLayout = true;
				this.listener.trigger('dialog:ready');

				// bind event trigger to item-json
				this.container.on('click', '[data-item-json]', (e) => {
					e.preventDefault();
					this.listener.trigger('dialog:item_added', JSON.parse(e.currentTarget.dataset.itemJson));
				});
			}

			// init inner tooltips and popovers
			this.container.find('[data-toggle="tooltip"]').tooltip({ container: '#digested-content-container' });
			this.container.find('[data-toggle="popover"]').popover({ container: '#digested-content-container' });

			this.show();

			// const contentContainerSelector    = '#digested-content-container';
			const streetviewContainerSelector = '#digested-streetview-container';

			// bind 'show streetview' action
			let svElement  = this.container[0].querySelector('#digested-streetview-box');
			this.container.one('shown.bs.collapse', streetviewContainerSelector, function (e) {
				Mape.createStreetViewPanorama( svElement.id, JSON.parse(svElement.dataset.svOpts) );
			});

			// ensure first collapsible container is always visible
			this.container.on('hidden.bs.collapse', '.collapse', function () {
				this.container.find('.collapse').eq(0).collapse('show');
			}.bind(this))
		});

		request.fail((xhr, error, errorText) => {
			this.listener.trigger('dialog:failed');

			iziToast.error({
				position: 'topCenter',
				title: 'Error',
				timeout: 3200,
				message: ( xhr.status == 0 ? 'El servidor no responde' : 'Ha ocurrido un error buscando el soporte'),
			});

		});

		request.always( xhr => this.progressBar.end() );
	}
};

export default ItemDialog